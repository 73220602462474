import React from "react"
import { Row, Col, Container, ListGroup, Card, Button } from "react-bootstrap"

import { Link, graphql, useStaticQuery } from "gatsby"

import { ChevronDown, FileX } from "react-bootstrap-icons"

import Img from "gatsby-image"

import WipeVid from "../media/compressedWipe.mp4"
import PillowVid from "../media/fluffing480.mp4"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function IndexPage() {
  const query = useStaticQuery(graphql`
    query {
      sink: file(relativePath: { eq: "wipeSink.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      living: file(relativePath: { eq: "living-room.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bathroom: file(relativePath: { eq: "bathroomCrop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      premierLiving: file(relativePath: { eq: "PL_Logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      creweShort: file(relativePath: { eq: "CSL_Logo2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sgLet: file(relativePath: { eq: "SG2_Logo.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout pageInfo={{ pageName: "index" }} homeBool="true">
      <SEO
        title="Commercial Cleaning Crewe"
        keywords={[
          `Cleaning`,
          `Crewe`,
          `Lettings Cleaning`,
          `professional cleaning`,
          `MM Lettings Cleaning`,
          `HMO Cleaning`,
          `Short Stay Cleaning`,
          `End of tenancy`,
          `cleaning service`,
          `Nantwich`,
          `Cheshire East`,
        ]}
        description="testestetestest"
      />
      <Container className="vidContainer" fluid style={{ height: "100vh" }}>
        <video
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
            zIndex: -10,
            objectFit: "cover",
            objectPosition: "center",
          }}
          autoplay="autoplay"
          loop
          muted
        >
          <source src={WipeVid} type="video/mp4" />
        </video>
        <h2 className=" text-center">Make your guests feel right at home</h2>
        <Link to="/services" className="btn btn-outline-primary">
          See what we can do for you
        </Link>
        <a href="#about">
          <ChevronDown
            className="chevron"
            color="white"
            opacity={0.6}
            size={"6rem"}
          />
        </a>
      </Container>
      <Container>
        <div id="about" className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading">
              Let us take care of the finishing touches.{" "}
            </h2>
            <p className="lead">
              First impressions are everything, and your hard work deserves an
              immaculate finish ready for your clients. Cleaners at MM Lettings
              Cleaning UK Ltd come with over 10 years of professional experience
              working with an array of satisfied customers. If you are located
              in Crewe or Cheshire East, look no further for your cleaning
              needs.
            </p>
          </div>
          <div className="col-md-5">
            <video
              autoplay="autoplay"
              loop
              muted
              style={{ height: "100%", width: "100%" }}
              className="rounded"
            >
              <source src={PillowVid} type="video/mp4"></source>
            </video>
          </div>
        </div>
        <hr className="featurette-divider" />
        <h2>Services</h2>
        <p className="text-muted">
          Our specialist cleans, with oven and carpet cleaning offered upon
          enquiry
        </p>
        <div className="row">
          <div className="col-lg-4">
            <Card>
              <Img fluid={query.living.childImageSharp.fluid} />
              <Card.Body>
                <Card.Text>
                  <strong>HMO Cleaning</strong>
                </Card.Text>
                <Card.Text>
                  Cleaning after short stay guests, includes bed change
                  alongside clean
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-4">
            <Card>
              <Img fluid={query.bathroom.childImageSharp.fluid} />
              <Card.Body>
                <Card.Text>
                  <strong>Short Stay</strong>
                </Card.Text>
                <Card.Text>
                  Cleaning after short stay guests, includes bed change
                  alongside clean
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-4">
            <Card>
              <Img fluid={query.sink.childImageSharp.fluid} />
              <Card.Body>
                <Card.Text>
                  <strong>End of Tenancy</strong>
                </Card.Text>
                <Card.Text>
                  Comprehensive clean for your property between tenants
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col col-12 mt-3" align="center">
            <Link to="/services" className="btn btn-primary">
              Find out More
            </Link>
          </div>
        </div>
        <hr className="featurette-divide" />
        <h2>
          MM Lettings Cleaning UK Ltd: Commercial Cleaning Services in Crewe and
          Cheshire
        </h2>
        <p className="text-muted">
          Welcome to MM Lettings Cleaning UK Ltd, your trusted partner for
          comprehensive lettings and commercial cleaning services in Crewe and
          the surrounding areas of Cheshire. We pride ourselves on delivering
          the highest quality cleaning for both commercial premises and domestic
          cleaning solutions tailored to suit the unique needs of property
          managers, landlords, and commercial property owners. With a commitment
          to excellence and a meticulous approach following inspection, we
          ensure that every space we clean is left spotless and inviting, ready
          for its next occupants.
        </p>
        <p className="text-muted">
          At MM Lettings Cleaning UK Ltd, we specialise in HMO (House in
          Multiple Occupation) cleaning, short stay cleaning, and end of tenancy
          cleaning. Our HMO cleaning services are designed to maintain high
          standards of cleanliness in shared living spaces, ensuring a hygienic
          environment for all residents. We understand the specific requirements
          of HMO properties and work diligently to keep communal areas,
          kitchens, bathrooms, and individual rooms in pristine condition,
          promoting a healthy and pleasant living experience.
        </p>
        <p className="text-muted">
          Our short stay cleaning services cater to properties used for holiday
          rentals, Airbnb, and other short-term accommodations. We offer
          flexible cleaning schedules to accommodate quick turnovers, ensuring
          that each property is immaculate for incoming guests. Our reliable
          team of cleaners pay close attention to detail with every job we take
          on, from fresh linens to sparkling bathrooms, to guarantee guest
          satisfaction and positive reviews for your domestic or commercial
          property.
        </p>
        <p className="text-muted">
          End of tenancy cleaning is one of our core commercial cleaning
          services, designed to meet the high standards required by landlords
          and letting agents. Our thorough cleaning process includes deep
          cleaning of kitchens, bathrooms, living areas, and bedrooms, ensuring
          that the property is restored to its original condition. We understand
          the importance of a flawless presentation for new tenants and strive
          to make the transition a success and as smooth as possible.
        </p>
        <p className="text-muted">
          The cleaners at MM Lettings Cleaning UK Ltd are committed to using
          environmentally friendly cleaning products and methods. Our trained
          and experienced staff are dedicated to providing exceptional business
          office cleaning services and commercial cleaning services that focus
          on reliability, efficiency, and attention to detail. From office
          cleaning services to carpet cleaning and deep clean services, our
          reliable team of cleaners offer professional tailored cleaning
          services for a range of business sectors.
        </p>
        <p className="text-muted">
          Whether you manage a commercial property, a residential letting, or
          require specialised cleaning services for HMOs, short stays, or end of
          tenancy, MM Lettings Cleaning UK Ltd is your go-to solution for all
          your cleaning needs in Crewe and Cheshire. Contact us today to
          experience the difference of a professional cleaning service and
          request a free quote.
        </p>
        <hr className="featurette-divider" />
        <h2>Clients</h2>
        <p className="text-muted">
          We have collaborated with numerous agencies and clients in the
          Cheshire East area
        </p>
        <div className="row justify-content-around">
          <div className="col-lg-3">
            {" "}
            <Img
              className="clientLogo"
              fluid={query.premierLiving.childImageSharp.fluid}
            />
          </div>
          <div className="col-lg-3">
            {" "}
            <Img
              className="clientLogo"
              fluid={query.creweShort.childImageSharp.fluid}
            />
          </div>
          <div className="col-lg-3">
            {" "}
            <Img
              className="clientLogo"
              fluid={query.sgLet.childImageSharp.fluid}
            />
          </div>
          <div className="col-lg-3 d-flex justify-content-center align-items-center">
            {" "}
            <p style={{ color: "black", fontSize: "1.5em" }}>
              Parkhurst Properties
            </p>
          </div>
        </div>
        <hr className="featurette-divider" />
        <h2 className="text-center">Didn't find what you were looking for?</h2>
        <p className="text-muted text-center">
          If you are in need of general cleaning services, both domestic or
          commercial, visit <a href="#">MM Cleaning services</a>
          <br></br>
          For specialised cleaning relating to serious biohazard matters, look
          no further than <a href="#">MM Trauma Cleaning</a>
        </p>
      </Container>
    </Layout>
  )
}
